import { Types } from "../types/types";

export interface IColors {
  pageBackgroundColor: string;
  mainHeaderBackgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
  alternateColor: string;
  sidebarBackgroundColor: string;
  sidebarColor: string;
  sidebarActiveColor: string;
  userMenuBackgroundColor: string;
  userMenuColor: string;
  userMenuActiveColor: string;
  buttonBackgroundColor: string;
  buttonColor: string;
  balance: string;
  bettingNavigationBackgroundColor: string;
  bettingNavigationColor: string;
  bettingShortcutsBackgroundColor: string;
  backgroundColorPaymentMethods: string;
  footerBackgroundColor: string;
  footerColor: string;
  tabsMainBackgroundColor: string;
  tabsBackgroundColor: string;
  tabsMainColor: string;
  tabsActiveBackgroundColor: string;
  tabsActiveColor: string;
  tabsColor: string;
  modalHeaderBackgroundColor: string;
  modalBackgroundColor: string;
  emailHeaderBackgroundColor: string;
  emailTitleColor: string;
  emailTextColor: string;
  emailButtonBackgroundColor: string;
  emailFooterBackgroundColor: string;
  emailFooterButtonBackgroundColor: string;
  emailFooterButtonColor: string;
}

export interface ILogo {
  url: string;
  name: string;
}

export interface ISocialMedia {
  show: boolean;
  url: string | null;
}

export interface IFooter {
  facebook: ISocialMedia;
  instagram: ISocialMedia;
  telegram: ISocialMedia;
  tiktok: ISocialMedia;
  twitter: ISocialMedia;
  youtube: ISocialMedia;
}

export interface IContentManagementState {
  colors: IColors;
  footer: IFooter;
  logo: File | ILogo | null;
  alternativeLogo: File | ILogo | null;
}
const initialState: IContentManagementState = {
  colors: {
    pageBackgroundColor: "#ffffff",
    mainHeaderBackgroundColor: "#ffffff",
    primaryColor: "#ffffff",
    secondaryColor: "#ffffff",
    alternateColor: "#ffffff",
    sidebarBackgroundColor: "#ffffff",
    sidebarColor: "#ffffff",
    sidebarActiveColor: "#ffffff",
    userMenuBackgroundColor: "#ffffff",
    userMenuColor: "#ffffff",
    userMenuActiveColor: "#ffffff",
    buttonBackgroundColor: "#ffffff",
    buttonColor: "#ffffff",
    balance: "#ffffff",
    bettingNavigationBackgroundColor: "#ffffff",
    bettingNavigationColor: "#ffffff",
    bettingShortcutsBackgroundColor: "#ffffff",
    backgroundColorPaymentMethods: "#ffffff",
    footerBackgroundColor: "#ffffff",
    footerColor: "#ffffff",
    tabsMainBackgroundColor: "#ffffff",
    tabsMainColor: "#ffffff",
    tabsBackgroundColor: "#ffffff",
    tabsActiveBackgroundColor: "#ffffff",
    tabsActiveColor: "#ffffff",
    tabsColor: "#ffffff",
    modalHeaderBackgroundColor: "#ffffff",
    modalBackgroundColor: "#ffffff",
    emailHeaderBackgroundColor: "#ffffff",
    emailTitleColor: "#ffffff",
    emailTextColor: "#ffffff",
    emailButtonBackgroundColor: "#ffffff",
    emailFooterBackgroundColor: "#ffffff",
    emailFooterButtonBackgroundColor: "#ffffff",
    emailFooterButtonColor: "#ffffff",
  },
  footer: {
    facebook: {
      show: true,
      url: null,
    },
    instagram: {
      show: true,
      url: null,
    },
    telegram: {
      show: true,
      url: null,
    },
    tiktok: {
      show: true,
      url: null,
    },
    twitter: {
      show: true,
      url: null,
    },
    youtube: {
      show: true,
      url: null,
    },
  },
  logo: null,
  alternativeLogo: null,
};

interface IContentManagementActions {
  type: Types;
  payload?: IColors | IFooter | ILogo | null;
}
export const contentManagementReducer = (
  state = initialState,
  action: IContentManagementActions
): IContentManagementState => {
  const { type, payload } = action;
  switch (type) {
    case Types.setUpContainerColors:
      return {
        ...state,
        colors: payload as IColors,
      };
    case Types.setLogoOperator:
      return {
        ...state,
        logo: payload as any,
      };
    case Types.setAlternativeLogoOperator:
      return {
        ...state,
        alternativeLogo: payload as any,
      };
    case Types.setFooter:
      return {
        ...state,
        footer: payload as IFooter,
      };
    default:
      return state;
  }
};
