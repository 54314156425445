import React, { useState } from "react";
import { Grid } from "@mui/material";
import { MyColorPicker } from "../../../../../../components/Custom/Table/MyColorPicker";
import { ColorResult } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../redux/reducers/rootReducer";
import "./formOperatorContent.scss";
import { setUpContainerColors } from "../../../../../../redux/actions/contentManagementActions";
import { useTranslation } from "react-i18next";

export const FormCustomColors = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors } = useSelector((state: AppState) => state.contentManagement);
  const [pickerOpen, setPickerOpen] = useState({
    index: "pageBackgroundColor",
    open: false,
  });

  const handleBgColorButtonClick = (index: string) => {
    setPickerOpen({
      index,
      open: !pickerOpen.open,
    });
  };
  const handleBgColorChange = (index: string, color: ColorResult) => {
    if (color) {
      const selectedColor = color.hex;
      dispatch(
        setUpContainerColors({
          ...colors,
          [index]: selectedColor,
        })
      );
    }
  };

  return (
    <Grid container className="operator-content-form">
      <Grid item container xs={4}>
        <div className="content-flex">
          <span>{t("contentManagement.pageBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.pageBackgroundColor}
            open={pickerOpen.index === "pageBackgroundColor" && pickerOpen.open}
            onColorButtonClick={() =>
              handleBgColorButtonClick("pageBackgroundColor")
            }
            onChange={(e) => handleBgColorChange("pageBackgroundColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.mainHeaderBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.mainHeaderBackgroundColor}
            open={
              pickerOpen.index === "mainHeaderBackgroundColor" &&
              pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("mainHeaderBackgroundColor")
            }
            onChange={(e) =>
              handleBgColorChange("mainHeaderBackgroundColor", e)
            }
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.primaryColor")}</span>
          <MyColorPicker
            defaultColor={colors?.primaryColor}
            open={pickerOpen.index === "primaryColor" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("primaryColor")}
            onChange={(e) => handleBgColorChange("primaryColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.secondaryColor")}</span>
          <MyColorPicker
            defaultColor={colors?.secondaryColor}
            open={pickerOpen.index === "secondaryColor" && pickerOpen.open}
            onColorButtonClick={() =>
              handleBgColorButtonClick("secondaryColor")
            }
            onChange={(e) => handleBgColorChange("secondaryColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.alternateColor")}</span>
          <MyColorPicker
            defaultColor={colors?.alternateColor}
            open={pickerOpen.index === "alternateColor" && pickerOpen.open}
            onColorButtonClick={() =>
              handleBgColorButtonClick("alternateColor")
            }
            onChange={(e) => handleBgColorChange("alternateColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.sidebarBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.sidebarBackgroundColor}
            open={
              pickerOpen.index === "sidebarBackgroundColor" && pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("sidebarBackgroundColor")
            }
            onChange={(e) => handleBgColorChange("sidebarBackgroundColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.sidebarColor")}</span>
          <MyColorPicker
            defaultColor={colors?.sidebarColor}
            open={pickerOpen.index === "sidebarColor" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("sidebarColor")}
            onChange={(e) => handleBgColorChange("sidebarColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.sidebarActiveColor")}</span>
          <MyColorPicker
            defaultColor={colors?.sidebarActiveColor}
            open={pickerOpen.index === "sidebarActiveColor" && pickerOpen.open}
            onColorButtonClick={() =>
              handleBgColorButtonClick("sidebarActiveColor")
            }
            onChange={(e) => handleBgColorChange("sidebarActiveColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.userMenuBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.userMenuBackgroundColor}
            open={
              pickerOpen.index === "userMenuBackgroundColor" && pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("userMenuBackgroundColor")
            }
            onChange={(e) => handleBgColorChange("userMenuBackgroundColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.userMenuColor")}</span>
          <MyColorPicker
            defaultColor={colors?.userMenuColor}
            open={pickerOpen.index === "userMenuColor" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("userMenuColor")}
            onChange={(e) => handleBgColorChange("userMenuColor", e)}
          />
        </div>
      </Grid>
      <Grid item container xs={4}>
        <div className="content-flex">
          <span>{t("contentManagement.userMenuActiveColor")}</span>
          <MyColorPicker
            defaultColor={colors?.userMenuActiveColor}
            open={pickerOpen.index === "userMenuActiveColor" && pickerOpen.open}
            onColorButtonClick={() =>
              handleBgColorButtonClick("userMenuActiveColor")
            }
            onChange={(e) => handleBgColorChange("userMenuActiveColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.buttonBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.buttonBackgroundColor}
            open={
              pickerOpen.index === "buttonBackgroundColor" && pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("buttonBackgroundColor")
            }
            onChange={(e) => handleBgColorChange("buttonBackgroundColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.buttonColor")}</span>
          <MyColorPicker
            defaultColor={colors?.buttonColor}
            open={pickerOpen.index === "buttonColor" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("buttonColor")}
            onChange={(e) => handleBgColorChange("buttonColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.balance")}</span>
          <MyColorPicker
            defaultColor={colors?.balance}
            open={pickerOpen.index === "balance" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("balance")}
            onChange={(e) => handleBgColorChange("balance", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.bettingNavigationBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.bettingNavigationBackgroundColor}
            open={
              pickerOpen.index === "bettingNavigationBackgroundColor" &&
              pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("bettingNavigationBackgroundColor")
            }
            onChange={(e) =>
              handleBgColorChange("bettingNavigationBackgroundColor", e)
            }
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.bettingNavigationColor")}</span>
          <MyColorPicker
            defaultColor={colors?.bettingNavigationColor}
            open={
              pickerOpen.index === "bettingNavigationColor" && pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("bettingNavigationColor")
            }
            onChange={(e) => handleBgColorChange("bettingNavigationColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.bettingShortcutsBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.bettingShortcutsBackgroundColor}
            open={
              pickerOpen.index === "bettingShortcutsBackgroundColor" &&
              pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("bettingShortcutsBackgroundColor")
            }
            onChange={(e) =>
              handleBgColorChange("bettingShortcutsBackgroundColor", e)
            }
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.backgroundColorPaymentMethods")}</span>
          <MyColorPicker
            defaultColor={colors?.backgroundColorPaymentMethods}
            open={
              pickerOpen.index === "backgroundColorPaymentMethods" &&
              pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("backgroundColorPaymentMethods")
            }
            onChange={(e) =>
              handleBgColorChange("backgroundColorPaymentMethods", e)
            }
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.footerBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.footerBackgroundColor}
            open={
              pickerOpen.index === "footerBackgroundColor" && pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("footerBackgroundColor")
            }
            onChange={(e) => handleBgColorChange("footerBackgroundColor", e)}
          />
        </div>
      </Grid>
      <Grid item container xs={4}>
        <div className="content-flex">
          <span>{t("contentManagement.footerColor")}</span>
          <MyColorPicker
            defaultColor={colors?.footerColor}
            open={pickerOpen.index === "footerColor" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("footerColor")}
            onChange={(e) => handleBgColorChange("footerColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.tabsMainBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.tabsMainBackgroundColor}
            open={
              pickerOpen.index === "tabsMainBackgroundColor" && pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("tabsMainBackgroundColor")
            }
            onChange={(e) => handleBgColorChange("tabsMainBackgroundColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.tabsMainColor")}</span>
          <MyColorPicker
            defaultColor={colors?.tabsMainColor}
            open={pickerOpen.index === "tabsMainColor" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("tabsMainColor")}
            onChange={(e) => handleBgColorChange("tabsMainColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.tabsBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.tabsBackgroundColor}
            open={pickerOpen.index === "tabsBackgroundColor" && pickerOpen.open}
            onColorButtonClick={() =>
              handleBgColorButtonClick("tabsBackgroundColor")
            }
            onChange={(e) => handleBgColorChange("tabsBackgroundColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.tabsActiveBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.tabsActiveBackgroundColor}
            open={
              pickerOpen.index === "tabsActiveBackgroundColor" &&
              pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("tabsActiveBackgroundColor")
            }
            onChange={(e) =>
              handleBgColorChange("tabsActiveBackgroundColor", e)
            }
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.tabsActiveColor")}</span>
          <MyColorPicker
            defaultColor={colors?.tabsActiveColor}
            open={pickerOpen.index === "tabsActiveColor" && pickerOpen.open}
            onColorButtonClick={() =>
              handleBgColorButtonClick("tabsActiveColor")
            }
            onChange={(e) => handleBgColorChange("tabsActiveColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.tabsColor")}</span>
          <MyColorPicker
            defaultColor={colors?.tabsColor}
            open={pickerOpen.index === "tabsColor" && pickerOpen.open}
            onColorButtonClick={() => handleBgColorButtonClick("tabsColor")}
            onChange={(e) => handleBgColorChange("tabsColor", e)}
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.modalHeaderBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.modalHeaderBackgroundColor}
            open={
              pickerOpen.index === "modalHeaderBackgroundColor" &&
              pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("modalHeaderBackgroundColor")
            }
            onChange={(e) =>
              handleBgColorChange("modalHeaderBackgroundColor", e)
            }
          />
        </div>
        <div className="content-flex">
          <span>{t("contentManagement.modalBackgroundColor")}</span>
          <MyColorPicker
            defaultColor={colors?.modalBackgroundColor}
            open={
              pickerOpen.index === "modalBackgroundColor" && pickerOpen.open
            }
            onColorButtonClick={() =>
              handleBgColorButtonClick("modalBackgroundColor")
            }
            onChange={(e) => handleBgColorChange("modalBackgroundColor", e)}
          />
        </div>
      </Grid>
      <Grid item container xs={12} marginTop={5}>
        <h3>{t("contentManagement.emailColors")}</h3>
        <Grid item container xs={12}>
          <Grid item container xs={4}>
            <div className="content-flex">
              <span>{t("contentManagement.emailHeaderBackgroundColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailHeaderBackgroundColor}
                open={
                  pickerOpen.index === "emailHeaderBackgroundColor" &&
                  pickerOpen.open
                }
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailHeaderBackgroundColor")
                }
                onChange={(e) =>
                  handleBgColorChange("emailHeaderBackgroundColor", e)
                }
              />
            </div>
            <div className="content-flex">
              <span>{t("contentManagement.emailTitleColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailTitleColor}
                open={pickerOpen.index === "emailTitleColor" && pickerOpen.open}
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailTitleColor")
                }
                onChange={(e) => handleBgColorChange("emailTitleColor", e)}
              />
            </div>
            <div className="content-flex">
              <span>{t("contentManagement.emailTextColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailTextColor}
                open={pickerOpen.index === "emailTextColor" && pickerOpen.open}
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailTextColor")
                }
                onChange={(e) => handleBgColorChange("emailTextColor", e)}
              />
            </div>
            <div className="content-flex">
              <span>{t("contentManagement.emailButtonBackgroundColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailButtonBackgroundColor}
                open={
                  pickerOpen.index === "emailButtonBackgroundColor" &&
                  pickerOpen.open
                }
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailButtonBackgroundColor")
                }
                onChange={(e) =>
                  handleBgColorChange("emailButtonBackgroundColor", e)
                }
              />
            </div>
          </Grid>
          <Grid item container xs={4}>
            <div className="content-flex">
              <span>{t("contentManagement.emailFooterBackgroundColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailFooterBackgroundColor}
                open={
                  pickerOpen.index === "emailFooterBackgroundColor" &&
                  pickerOpen.open
                }
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailFooterBackgroundColor")
                }
                onChange={(e) =>
                  handleBgColorChange("emailFooterBackgroundColor", e)
                }
              />
            </div>
            <div className="content-flex">
              <span>
                {t("contentManagement.emailFooterButtonBackgroundColor")}
              </span>
              <MyColorPicker
                defaultColor={colors?.emailFooterButtonBackgroundColor}
                open={
                  pickerOpen.index === "emailFooterButtonBackgroundColor" &&
                  pickerOpen.open
                }
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailFooterButtonBackgroundColor")
                }
                onChange={(e) =>
                  handleBgColorChange("emailFooterButtonBackgroundColor", e)
                }
              />
            </div>
            <div className="content-flex">
              <span>{t("contentManagement.emailFooterButtonColor")}</span>
              <MyColorPicker
                defaultColor={colors?.emailFooterButtonColor}
                open={
                  pickerOpen.index === "emailFooterButtonColor" &&
                  pickerOpen.open
                }
                onColorButtonClick={() =>
                  handleBgColorButtonClick("emailFooterButtonColor")
                }
                onChange={(e) =>
                  handleBgColorChange("emailFooterButtonColor", e)
                }
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
